import React, { useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import {
  NoImgTopSection,
  Section,
  Description,
  media,
  DescriptionWrapper,
} from '~/styles';
import { useIntersection } from '~/hooks';
import Card from '~/components/education/Card';

const EducationPage: React.VFC<PageProps<GatsbyTypes.EducationPageQuery>> = ({
  data: { rawWorks },
}) => {
  const { t, i18n } = useTranslation();
  const [isTextIntersection, textIntersectionRef] =
    useIntersection<HTMLParagraphElement>();

  const works = useMemo(
    () =>
      rawWorks.nodes.map((val) => {
        const articleJa = {
          name: val.name,
          locale: val.i18n_lang,
        };

        const workEn =
          val.i18n_refs && val.i18n_refs?.length > 0
            ? val.i18n_refs[0]?.ref
            : null;

        const articleEn = workEn
          ? {
              name: workEn.name,
              locale: workEn.i18n_lang,
            }
          : null;

        const thumbnail = {
          image: {},
          vimeo: '',
          youtube: '',
        };

        if (val.thumbnail && val.thumbnail.length > 0) {
          // eslint-disable-next-line default-case,no-underscore-dangle
          switch (val.thumbnail[0]?._type) {
            case 'image':
              thumbnail.image = {
                asset: val.thumbnail[0]?.asset?.gatsbyImageData,
                hotspot: val.thumbnail[0]?.hotspot,
              };
              break;
            case 'vimeo':
              thumbnail.vimeo = val.thumbnail[0]?.vimeoId;
              break;
            case 'youtube':
              thumbnail.youtube = val.thumbnail[0]?.youtubeId;
              break;
          }
        }

        return {
          slug: val.slug?.current,
          thumbnail,
          sns: {
            text: val.sns?.text ?? '',
            link: val.sns?.link ?? '',
          },
          year: val.year,
          localizedItem:
            i18n.language === 'en' && articleEn ? articleEn : articleJa,
        };
      }),
    [i18n.language, rawWorks],
  );

  return (
    <>
      <SEO title="Education" />
      <Layout>
        <NoImgTopSection>
          <TitleWithCatchCopy
            title="education.title"
            catchCopy="education.catchCopy"
            catchSub="education.catchSub"
            byKey
            isIntersection
          />
        </NoImgTopSection>
        <Section>
          <DescriptionWrapper isIntersection={isTextIntersection} showAnim>
            <StyledDescription ref={textIntersectionRef}>
              {t('education.description')}
              <br />
              <OuterLink
                href="https://cgworld.jp/interview/201909-safehouse-matsushima.html"
                target="_blank"
                rel="noreferrer"
              >
                <Trans i18nKey="education.interview" />
              </OuterLink>
            </StyledDescription>
            <StyledDescription>{t('education.examples')}</StyledDescription>
          </DescriptionWrapper>
          <List>
            {works.map((work) => (
              <Card
                thumbnail={work.thumbnail}
                year={work.year}
                name={work.localizedItem.name ?? ''}
                sns={work.sns ?? null}
                key={work.slug}
              />
            ))}
          </List>
        </Section>
      </Layout>
    </>
  );
};

const StyledDescription = styled(Description)`
  text-align: center;
`;

const OuterLink = styled.a`
  text-decoration: underline;
`;

const List = styled.ul`
  display: grid;

  width: 100%;
  margin: 0;
  padding: 0;

  ${media.mdUp} {
    grid-gap: 96px;
  }

  ${media.mdDown} {
    grid-gap: 64px;
  }
`;

export default EducationPage;

export const query = graphql`
  query EducationPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    rawWorks: allSanityEducationWork(
      filter: { i18n_lang: { eq: "ja" } }
      sort: { fields: publishedAt, order: ASC }
    ) {
      nodes {
        name
        slug {
          current
        }
        sns {
          link
          text
        }
        thumbnail {
          ... on SanityImage {
            _key
            _type
            asset {
              gatsbyImageData
            }
            hotspot {
              x
              y
            }
          }
          ... on SanityVimeo {
            _key
            _type
            vimeoId
          }
          ... on SanityYoutube {
            _key
            _type
            youtubeId
          }
        }
        year
        i18n_lang
        i18n_refs {
          ref {
            ... on SanityEducationWork {
              name
              i18n_lang
            }
          }
        }
      }
    }
  }
`;
