import React from 'react';
import { useMeasure } from 'react-use';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { Trans } from 'gatsby-plugin-react-i18next';
import { colors, media, commonKeyframes, easings } from '~/styles';
import { useIntersection } from '~/hooks';

interface CardProps {
  thumbnail: any;
  year?: number;
  name: string;
  sns?: {
    text: string;
    link: string;
  };
}

const Card: React.VFC<CardProps> = ({ thumbnail, year, name, sns }) => {
  const [isIntersection, intersectionRef] = useIntersection<HTMLLIElement>();
  const [thumbnailRef, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Wrapper ref={intersectionRef} isIntersection={isIntersection}>
      {thumbnail.youtube && (
        <iframe
          width="100%"
          height={width * 0.5625}
          src={`https://www.youtube.com/embed/${thumbnail.youtube}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      {thumbnail.vimeo && (
        <iframe
          src={`https://player.vimeo.com/video/${thumbnail.vimeo}`}
          width="100%"
          height={width * 0.5625}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      {thumbnail.image && thumbnail.image.asset && (
        <ThumbnailWrapper>
          <Thumbnail
            image={thumbnail.image.asset}
            alt="thumbnail"
            style={
              thumbnail.hotspot &&
              ({
                '--image-position': `${100 * thumbnail.image.hotspot.y}%`,
              } as React.CSSProperties)
            }
          />
        </ThumbnailWrapper>
      )}

      <TextWrapper ref={thumbnailRef}>
        {year && (
          <Year>
            <Trans i18nKey="education.years" values={{ year: `${year}` }} />
          </Year>
        )}

        <Name>{name}</Name>
        {sns && (
          <Account href={sns.link} target="_blank" rel="noreferrer">
            {sns.text}
          </Account>
        )}
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.li<{ isIntersection: boolean }>`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  opacity: 0;
  transform: translateY(3em);

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} forwards,
        ${commonKeyframes.slideIn} 0.6s ${easings.easeOutCubic} forwards;
    `};
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }
`;

const Thumbnail = styled(GatsbyImage)`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.6s ${easings.easeOutCubic};

  img {
    object-position: center var(--image-position) !important;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.5) inset;
    opacity: 1;
    transition: opacity 0.6s ${easings.easeOutCubic};
  }

  &:hover {
    transform: scale(1.02);

    &::after {
      opacity: 0;
    }
  }
`;

const TextWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5em;
  font-size: 16px;
  justify-content: center;
  line-height: 1;

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const Year = styled.p`
  margin: 0;
`;

const Name = styled.p`
  margin: 0;
`;

const Account = styled.a`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: auto 0 -2px;
    height: 1px;
    width: 0;
    background-color: ${colors.white};
    transition: width 0.6s ${easings.easeOutCubic};
  }

  &:hover {
    &:before {
      width: 100%;
      transition-duration: 0.3s;
    }
  }
`;

export default Card;
